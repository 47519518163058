/* eslint-disable react-hooks/exhaustive-deps */
import type { FC } from "react";
import { useState, useEffect, useRef } from "react";
import type { Theme, SxProps } from "@mui/material";
import { TextField, InputAdornment, MenuItem } from "@mui/material";
import SearchIcon from "src/icons/Search";
import { useSearchParams } from "react-router-dom";
import { getAllQuery } from "src/utils/helpers/query-string-url.helper";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";

interface TextProps {
  setValues: (params: any) => void;
  values?: any;
  name: string;
  placeholder?: string;
  label?: string;
  sx?: SxProps<Theme>;
}

export const TextFilterBackend: FC<TextProps> = ({
  setValues,
  values,
  name,
  placeholder,
  label,
  sx,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isRender = useRef(false);
  const text = searchParams.get(name) == null ? "" : searchParams.get(name);
  const [state, setState] = useState(text);
  const params = getAllQuery();

  useEffect(() => {
    if (isRender.current)
      setValues({ ...values, ...getAllQuery(), [name]: state }); // Set undefined to remove the filter entirely
    isRender.current = true;
  }, [state]);

  return (
    <TextField
      value={state || ""}
      onChange={(e) => {
        setState(e.target.value);
        //@ts-ignore
        setSearchParams({ ...params, page: 0, [name]: e.target.value });
      }}
      placeholder={placeholder}
      label={label}
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon fontSize="small" />
          </InputAdornment>
        ),
      }}
      sx={{
        "& .MuiInputBase-root": {
          borderRadius: "999px",
          ...(Boolean(values[name]) && {
            color: "primary.main",
          }),
        },
        "& input": {
          border: "none !important",
          outline: "none",
        },

        ...sx,
      }}
    />
  );
};

interface SelectProps {
  setValues: (params: any) => void;
  values: any;
  name: string;
  label: string;
  sx?: SxProps<Theme>;
  withAll?: boolean;
  options?: {
    label: string;
    value: string | number;
  }[];
}

export const SelectFilterBackEnd: FC<SelectProps> = ({
  setValues,
  values,
  name,
  label,
  withAll,
  options,
  sx,
}) => {
  const setSearchParams = useSearchParams()[1];
  return (
    <TextField
      select
      defaultValue=""
      label={label}
      value={values[name]}
      onChange={(e) => {
        setValues({ ...values, page: 0, [name]: e.target.value });
        //@ts-ignore
        setSearchParams({ ...getAllQuery(), page: 0, [name]: e.target.value });
      }}
      sx={{
        maxWidth: {
          md: "220px",
          xs: "100%",
        },
        width: "100%",
        "& .MuiInputBase-root": {
          borderRadius: "999px",
          color: "primary.main",
        },
        WebkitBoxShadow: "0 0 0 30px #161819 inset !important",
        "& input:-webkit-autofill:hover": {
          WebkitBoxShadow: "0 0 0 30px #161819 inset !important",
        },
        "& input:-webkit-autofill:active": {
          WebkitBoxShadow: "0 0 0 30px #161819 inset !important",
        },
        "& input:-webkit-autofill:focus": {
          WebkitBoxShadow: "0 0 0 30px #161819 inset !important",
        },
        ...sx,
      }}
    >
      {withAll && <MenuItem value="">All</MenuItem>}
      {options?.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

interface DatePickerProps {
  setValues: (params: any) => void;
  values?: any;
  name: string;
  placeholder?: string;
  label?: string;
}

export const DatePickerFilterBackend: FC<DatePickerProps> = ({
  setValues,
  values,
  name,
  placeholder,
  label,
}) => {
  const [, setSearchParams] = useSearchParams();
  const isRender = useRef(false);
  // const text =
  //   searchParams.get(name) === null
  //     ? ""
  //     : new Date(searchParams.get(name) || new Date());
  const [state, setState] = useState(values[name]);
  const params = getAllQuery();

  useEffect(() => {
    if (state)
      if (isRender.current)
        setValues({ ...values, ...getAllQuery(), [name]: state }); // Set undefined to remove the filter entirely
    isRender.current = true;
  }, [state]);

  return (
    <DesktopDatePicker
      label={label}
      //@ts-ignore
      value={state || ""}
      // inputFormat="yyyy-MM-dd"
      onChange={(e: any) => {
        const day = format(new Date(e || Date()), "yyyy-MM-dd");
        setState(day);
        //@ts-ignore
        setSearchParams({ ...params, page: 0, [name]: day });
      }}
      slotProps={{
        textField: {
          error: false,
        },
      }}
      disableFuture
      //@ts-ignore
      renderInput={({ error, ...tProps }: any) => {
        return (
          <TextField
            sx={{
              "& .MuiInputBase-root": {
                borderRadius: "999px",
                color: "primary.main",
              },
            }}
            required={false}
            placeholder={placeholder}
            error={false}
            fullWidth
            {...tProps}
          />
        );
      }}
    />
  );
};
